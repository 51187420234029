import React from 'react';
import { IntegrationSystems } from 'shared/data-layer/integration';
import { CopyButton } from '../../utils';
import { IntegrationConfigRenderBase } from './integrationConfigRenderBase';

export class RasaApiKeySystemConfigRender extends IntegrationConfigRenderBase {
  protected supportsFilter: boolean = false;

  protected initializeCustomState(): Promise<void> {
    return new Promise((resolve, reject) => {
      if (this.userEmail === this.props.data.creator_email) {
        this.setState({ isKeyCreator: true }, resolve);
      } else {
        resolve();
      }
    });
  }

  protected renderCustomBox() {
    return (
      <div className="rasa-api-key-wrapper">
        {this.state.isKeyCreator ||
        !this.props.data.id ||
        !this.props.data.is_active ? (
          <div className="audience-name-wrapper">
            {!!this.props.data.is_active && (
              <div>
                <div className="field-label-credentials">rasa.io API Key</div>
                <div className="api-key-box">
                  <div className="api-key-text">
                    {!this.rasaApiKey
                      ? 'Click on Generate a Key'
                      : this.rasaApiKey}
                  </div>
                </div>
              </div>
            )}
            {!!this.rasaApiKey && !!this.props.data.is_active && (
              <CopyButton text={this.rasaApiKey} />
            )}
          </div>
        ) : (
          <div className="api-key-box">
            {`An active rasa.io API Key has already been created by ${this.props.data.creator_email}`}
          </div>
        )}
      </div>
    );
  }

  protected renderConnect() {
    return (
      <div>
        {this.props.hasCognitoIdentifier &&
        (this.state.isKeyCreator ||
          !this.props.data.id ||
          !this.props.data.is_active) ? (
          <div>
            {this.generateAndConnect()}
            <DirectionsBox
              directions={
                !!this.props.data.is_active
                  ? RasaApiKeySystemsConnectInstructions[
                      this.props.data.systemName
                    ].alreadyConnected
                  : RasaApiKeySystemsConnectInstructions[
                      this.props.data.systemName
                    ].connect
              }
            />
          </div>
        ) : (
          <div>
            Please contact{' '}
            <a
              href={`mailto:support@rasa.io?subject=${this.props.data.systemName} Connection`}
            >
              Customer Support
            </a>{' '}
            to generate your API key.
          </div>
        )}
      </div>
    );
  }

  protected renderDisconnect() {
    return null;
  }

  private generateAndConnect() {
    const buttonClassname =
      this.state.isKeyCreator || !this.props.data.creator_email
        ? 'clickable-item save-button-enabled'
        : 'not-allowed save-button-disabled';
    return (
      <div className="generate-key-and-disconnect-container">
        {this.props.data.is_active ? (
          <div
            className={`save-button generate ${buttonClassname}`}
            onClick={(e) => this.generateRasaApiKey(this.communityId)}
          >
            Refresh your API Key
          </div>
        ) : !this.props.data.id ? (
          <div
            className={`save-button generate ${buttonClassname}`}
            onClick={(e) => this.generateRasaApiKey(this.communityId)}
          >
            {'Generate your API Key'}
          </div>
        ) : null}
        {!!this.rasaApiKey && (
          <div>
            {this.props.data.is_active ? (
              <div
                className={`save-button ${buttonClassname}`}
                onClick={(e) => this.disconnect()}
              >
                Disconnect
              </div>
            ) : (
              <div
                className={`save-button ${buttonClassname}`}
                onClick={(e) => this.reconnect()}
              >
                Reconnect
              </div>
            )}
          </div>
        )}
      </div>
    );
  }
}

const DirectionsBox = ({ directions }: any) => (
  <div className="directions-box">
    <ol>
      {directions.map((direction, i) => (
        <li key={i}>{direction}</li>
      ))}
    </ol>
  </div>
);

const RasaApiKeySystemsConnectInstructions = {
  [IntegrationSystems.Pabbly]: {
    connect: [
      'Click the button above to generate your rasa.io API key',
      'Copy your key',
      'Use your rasa.io credentials and API Key to connect to rasa.io',
    ],
    alreadyConnected: [
      'Copy your key',
      'Use your rasa.io credentials and API Key to connect to rasa.io',
    ],
  },
  [IntegrationSystems.Pipedream]: {
    connect: [
      'Click the button above to generate your rasa.io API key',
      'Copy your key',
      'Use your rasa.io credentials and API Key to connect to rasa.io',
    ],
    alreadyConnected: [
      'Copy your key',
      'Use your rasa.io credentials and API Key to connect to rasa.io',
    ],
  },
  [IntegrationSystems.SyncSpider]: {
    connect: [
      'Click the button above to generate your rasa.io API key',
      'Copy your key',
      'Use your rasa.io credentials and API Key to connect to rasa.io',
    ],
    alreadyConnected: [
      'Copy your key',
      'Use your rasa.io credentials and API Key to connect to rasa.io',
    ],
  },
  [IntegrationSystems.Zapier]: {
    connect: [
      'Click the button above to generate your rasa.io API key',
      'Copy your key',
      <div>
        <a href="https://zapier.com/app/zaps" target="_blank" rel="noopener">
          Login to your Zapier account
        </a>
      </div>,
      'Create new Zap using your rasa.io credentials and API Key',
    ],
    alreadyConnected: [
      'Copy your key',
      <div>
        <a href="https://zapier.com/app/zaps" target="_blank" rel="noopener">
          Login to your Zapier account
        </a>
      </div>,
      'Create new Zap using your rasa.io credentials and API Key',
    ],
  },
  [IntegrationSystems.Integrately]: {
    connect: [
      'Click the button above to generate your rasa.io API key',
      'Copy your key',
      <div>
        <a href="https://integrately.com/" target="_blank" rel="noopener">
          Login to your integrately account
        </a>
      </div>,
      'Use your rasa.io credentials and API Key to connect to rasa.io',
    ],
    alreadyConnected: [
      'Copy your key',
      <div>
        <a href="https://integrately.com/" target="_blank" rel="noopener">
          Login to your integrately account
        </a>
      </div>,
      'Use your rasa.io credentials and API Key to connect to rasa.io',
    ],
  },
};
